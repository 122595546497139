<template lang="pug">
.popular-users-component
    h5 {{header}}
    HorizontalProfilesCarousels(:profiles="profiles")
</template>

<script>
import UserName from "./UserName.vue";
import ProfileCard from "./ProfileCard.vue";
import HorizontalProfilesCarousels from './HorizontalProfilesCarousels.vue';

export default {
    props: ["post"],
    components: {UserName, ProfileCard, HorizontalProfilesCarousels},
    computed:{
        header(){
            if(!this.post || !this.post.content) return '';
            return this.post.content.split("\n")[0];
        },
        profiles(){
            if(!this.post || !this.post.content) return [];
            let profiles = []
            this.post.content.split("\n").slice(1).forEach((p)=>{
                if(p) profiles.push(p.split(':')[1]);
            })
            return profiles
        }
    }
}
</script>

<style lang="sass" scoped>
</style>