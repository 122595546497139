<template lang="pug">
.new-post.card()
    .card-body.text-start
        span.text-secondary {{ $t("message.statusUpdate") }}:
        .form-floating
            textarea.form-control(type="text" v-model="newContent" placeholder=" " name="post" autocomplete="off" @paste="onPaste")
            label(for="") {{(profile._id && profile._id != UserStore.me._id) ? $t("message.saySomethingTo") + profile.profile.firstName + " " + profile.profile.lastName : $t("message.whatIsNew")}}
        input#uploadImages(type="file" style="display:none" accept="image/*" multiple)
        .row.actions(style="padding-top:8px")
            .col.text-start
                .p-2
                    span {{ $t("message.add") }}:
                        span.pb-1.material-icons(@click="openFileSelector") add_photo_alternate
                        //span.material-icons location_on
                        //span.material-icons mood
                        //span.material-icons alternate_email
            .col.text-end
                //span.material-icons visibility
                .form-check.form-check-inline.form-switch(v-if='!profile._id && UserStore.me.superuser')
                    input.form-check-input(type="checkbox" value="" id="newsCheckbox" v-model='isNews')
                    label.form-check-label(for="newsCheckbox") {{ $t("message.newsPost") }}
                button.btn.btn-outline-secondary(@click="newPost") {{ $t("message.post") }}
        Media(:content="addedTags")
</template>

<script>
import Media from "./Media.vue";
import { UserStore } from './../UserStore.js';

export default {
    data() {
        return {
            newContent: "",
            addedTags: "",
            isNews: false,
            UserStore
        }
    },
    components: {Media},
    props: ["profile"],
    methods: {
        newPost(){
            this.$emit('newPost', this.newContent + " " + this.addedTags, this.isNews);
            this.newContent = "";
            this.addedTags = "";
        },
        async uploadFile(file) {
            const fd = new FormData();
            fd.append("banner", file);
            return fetch("https://social.emmint.com/upload.php", {
                    method: "POST",
                    body: fd,
                })
                .then((res) => res.json())
                .then((data) => {
                    this.addedTags += "@image:" + data.fileName + " ";
                })
                .catch((err) => console.error(err));
        },
        openFileSelector() {
            document.getElementById("uploadImages").click();
        },
        onPaste(evt){
            let content = evt.clipboardData.getData('text/plain');
            if(content.substring(0,32) == "https://www.youtube.com/watch?v="){
                let videoId = content.substring(32);
                this.addedTags += "@youtube:" + videoId + " ";
                evt.preventDefault();
            }
        }
    },
    mounted() {
        let input = document.getElementById("uploadImages");
        input.addEventListener("change", () => {
            for(let i=0; i<input.files.length; i++){
                this.uploadFile(input.files[i]);
            }
        });
    }
}
</script>

<style scoped lang="sass">


.new-post
    margin-bottom: 0px
    textarea
        transition: min-height 0.7s
    &:hover
        textarea
            min-height: 120px !important
        .actions
            display: flex
</style>
