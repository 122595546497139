<template>
  <div style="padding: 56.25% 0 0 0; position: relative">
    <iframe
      :src="'https://player.vimeo.com/video/' + videoId + '?h=4eefbba58b'"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
      style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
    ></iframe>
  </div>
  
</template>

<script>
export default {
    props: ["videoId"]
};
</script>

<style>
</style>