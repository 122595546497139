<template lang="pug">
.post.card.text-start(:class="{removed: removed, news: post.nonOrganicType == 'News', course: postProfile.isCourse}")
    .card-body(:class="{long: cleanContent.length > 300, expanded}" style="position:relative; overflow-x:hidden;")
        .regular-content(v-if="!post.nonOrganicType")
            .edit-post.m-2(v-if='UserStore.me._id == post.profileid && showEdit && (UserStore.me.superuser || UserStore.me.isGroup)')
                .form-floating.m-1
                    textarea.form-control(type="text" v-model="post.content" placeholder=" " rows="4" name="post" autocomplete="off" style="height: auto")
                    label(for="") Edit Post:
                .text-center(@click="editPost")
                    button.btn.btn-primary Save edit
            .person(style="margin-bottom: -35px;")
                .card-title(style="display:flex")
                    UserName(:id="post.profileid" style="font-weight: bold; text-wrap: nowrap;" :showImgCircle="true")
                    span.to-user(v-if="post.toProfile && post.toProfile != post.profileid" style="display:flex; text-wrap: nowrap;")
                        span.material-icons chevron_right
                        UserName(:id="post.toProfile" style="font-weight: bold")
            .content()
                p.card-text(v-html='cleanContent')
            .read-more(v-if="cleanContent.length > 300")
                p.fw-bold.pointer(@click="expanded = !expanded") {{expanded ? "... read less" : "... read more"}}
            Media(:content="post.content" :user="user" :post="post")
            .media-subscribe-text
                p 
                    button.btn.btn-outline-secondary(@click="$router.push('/Me?subscribe=1')") Subscribe here! 
                    |  to have access to all the multimedia.
            .row.delete-post.text-end.small.text-muted(v-if='UserStore.me._id == post.profileid')
                p.m-1.pointer(@click="deletePost")
                    span.material-icons delete
                p.m-1.pointer(@click="showEditPost" v-if="(UserStore.me.superuser || UserStore.me.isGroup)")
                    span.material-icons edit
            //.row.text-end.small.text-muted(style="padding-right: 20px")
                .notification(v-for="n in notifications")
                    span.material-icons notifications_active
                    span {{n.body}}
        .non-organic-content(v-if="post.nonOrganicType")
            .popular-users(v-if="post.nonOrganicType == 'PopularUsers'")
                PopularUsers(:post="post")
            .popular-groups(v-if="post.nonOrganicType == 'PopularGroups'")
                PopularGroups(:post="post")
            .news-content(v-if="post.nonOrganicType == 'News'")
                .edit-post.m-2(v-if='UserStore.me._id == post.profileid && showEdit && (UserStore.me.superuser || UserStore.me.isGroup)')
                    .form-floating.m-1
                        textarea.form-control(type="text" v-model="post.content" placeholder=" " rows="4" name="post" autocomplete="off" style="height: auto")
                        label(for="") Edit Post:
                    .text-center(@click="editPost")
                        button.btn.btn-primary Save edit
                h3.text-start
                    span.badge.bg-primary News Post
                .content(v-if="cleanContent.trim()" v-html="cleanContent")
                .row.delete-post.text-end.small.text-muted(v-if='UserStore.me._id == post.profileid')
                    p.m-0.pointer(@click="deletePost")
                        span.material-icons delete
                    p.m-1.pointer(@click="showEditPost" v-if="(UserStore.me.superuser || UserStore.me.isGroup)")
                        span.material-icons edit
                Media(:content="post.content" :user="user" :post="post")
            //p {{post.content}}
    .card-footer(v-if="!post.nonOrganicType || post.nonOrganicType == 'News'")
        .reaction.inline
            button.btn(@click="reaction") 
                span.material-icons {{Object.keys(post.reactions).length ? (ILikeThis ? "volunteer_activism" : 'favorite') : 'favorite_border'}}
                span.muted.number-reactions {{Object.keys(post.reactions).length}} 
        .comments-count.inline
            button.btn(data-bs-toggle="collapse" :href="'#comments_' + post._id" role="button" aria-expanded="false" :aria-controls="'comments_' + post._id") 
                span.material-icons forum
                span.muted.number-reactions {{post.comments.length}} 
        .share.inline
            button.btn
                span.material-icons ios_share
        .share.inline
            button.btn(@click="bookmark") 
                span.material-icons(v-if="!post.bookmarks || !post.bookmarks.includes(user._id) ") bookmark_add
                span.material-icons(v-else) bookmark_added
        .date.inline(style="position:absolute; right: 10px; padding-top: 10px")
            span.small.text-muted(style="padding-left:5px") {{fromNow}}
    .card-footer.collapse(:id="'comments_' + post._id")
        .comments
            .new-comment.card
                .card-body.row
                    .col
                        input.form-control(type='text' name="new comment" placeholder="New comment" v-model="newCommentContent" :id="'NewComment' + post._id" autocomplete="off")
                    .col-auto.pointer
                        span.material-icons(@click="newComment") add_comment
            comment(v-for="(c, idx) in post.comments" :comment="c" :postid="post._id" :index="idx" @newCommentReaction="newCommentReaction")
                
</template>

<script>
import API from "../API.js";
import Comment from "./Comment.vue";
import Media from "./Media.vue";
import UserName from "./UserName.vue";
import Vimeo from "./Vimeo.vue";
import Plyr from "./Plyr.vue";
import PopularUsers from "./PopularUsersPost.vue";
import PopularGroups from "./PopularGroupsPost.vue";
import { UserStore } from "./../UserStore.js";
//import ImageCircle from "./fundamentals/ImageCircle.vue";

const convertLinks = (input) => {

    let text = input;

    text = text.replace(/(?:www|https?)[^\s]+/g, function (replace, index) {
        console.log(replace, index)
        if (!(replace.match(/(http(s?)):\/\//))) { replace = 'http://' + replace }
        let linkText = replace.split('/')[2];
        if (linkText.substring(0, 3) == 'www') { linkText = linkText.replace('www.', '') }
        if (linkText.match(/youtu/)) {

            let youtubeID = replace.split('/').slice(-1)[0];
            return '<div class="video-wrapper"><iframe src="https://www.youtube.com/embed/' + youtubeID + '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>';
        }
        else if (linkText.match(/vimeo/)) {
            let vimeoID = replace.split('/').slice(-1)[0];
            return '<div class="video-wrapper"><iframe src="https://player.vimeo.com/video/' + vimeoID + '" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div>';
        }
        else {
            console.log('replace', replace, linkText)
            return '<a href="' + replace + '" target="_blank">' + linkText + '</a>';
        }
    });
    return text;
}

export default {
    name: "Post",
    props: ["post", "index", "user"],
    data() {
        return {
            newCommentContent: "",
            userid: API.currentUserId(),
            expanded: false,
            UserStore,
            removed: false,
            postProfile: {},
            showEdit: false,
        }
    },
    mounted() {
        if (!this.post.profileid) return 0;
        API.getUserProfile(this.post.profileid).then((p) => {
            this.postProfile = p;
        });
    },
    computed: {
        fromNow() {
            return window.moment(this.post.createdAt).fromNow();
        },
        cleanContent() {
            const content = convertLinks(this.post.content)
            return content.replace(/@[A-z]+:.+\w/g, '')
        },
        ILikeThis() {
            return this.post.reactions[this.user._id] ? true : false;
        },
        notifications() {
            if (this.user.notifications)
                return this.user.notifications.filter((n) => {
                    return n.postid == this.post._id;
                });
            return [];
        }
    },
    components: {
        Comment,
        UserName,
        Vimeo,
        Plyr,
        Media,
        PopularUsers,
        PopularGroups
    },
    methods: {
        newComment() {
            if (!this.newCommentContent) return 0;
            this.$emit("newPostComment", this.post._id, this.index, this.newCommentContent);
            this.$emit("newPostBookmark", this.post._id, this.index);
            this.newCommentContent = "";
        },
        reaction() {
            this.$emit("newPostReaction", this.post._id, this.index);
        },
        bookmark() {
            this.$emit("newPostBookmark", this.post._id, this.index);
        },
        newCommentReaction(postid, commentDate, commentIdx) {
            this.$emit("newCommentReaction", postid, commentDate, commentIdx, this.index);
        },
        deletePost() {
            API.deletePost(this.post._id).then(() => {
                this.removed = true;
            });
        },
        showEditPost() {
            this.showEdit = true;
        },
        editPost() {
            this.showEdit = false;
            API.updatePost(this.post).then(() => {
                this.showEdit = false;
            });
        }
    }
}
</script>

<style lang="sass" scoped>
.post
    //margin-bottom: 20px
    border-radius: 0px
    transition: background-color 0.8s
    .card-body
        padding-bottom: 0
    .card-footer
        padding-top: 0
    .number-reactions
        position: absolute
        padding-left: 3px
    .content
        font-size: 1.1rem
        white-space: pre-wrap
        word-wrap: break-word
        padding-left: 56px
        margin-bottom: 5px
    .inline
        display: inline
        margin-right: 10px
    .card-footer
        transition: background-color 0.8s, opacity 0.8s
        background-color: #fff
        border: none
        padding-left: 56px
        opacity: 0.5
        button .material-icons
            font-size: 1.5em
            //padding-left: 25px
    .delete-post
        display: none
        position: absolute
        bottom: 10px
        right: 10px
        span
            &:hover
                color: #B05858 !important
    &:hover
        transition: background-color 0.8s
        background-color: #FAFAFA
        .card-footer
            transition: background-color 0.8s, opacity 0.8s
            background-color: #FAFAFA
            opacity: 1
        .delete-post
            display: block
    &.removed
        display: none
.long
    .content
        font-size: 1rem
        max-height: 200px
        overflow: hidden
        transition: max-height 0.7s ease-in-out  
    .news-content
        .content
            max-height: 200vh
.expanded
    .content
        max-height: 100vh
.comments
    .card
        margin-bottom: 5px 

.ILikeThis
    color: red

.media-subscribe-text
    display: none
//.course
    //.media-content
        //display: none
    //.media-subscribe-text
        //display: block
    //&.subscriptor
        //.media-content
            //display: block
        //.media-subscribe-text
            //display: none
.subscriptor
    .course
        .media-content
            display: block
        .media-subscribe-text
            display: none
</style>