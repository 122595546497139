<template lang="pug">
.popular-users-component
    h5 Popular groups to follow:
    //p {{post}}
    HorizontalProfilesCarousels
        .profile(v-for="p in profiles")
            GroupCard(:id="p" style="width: 234px").m-1
</template>

<script>
import GroupCard from "./GroupsCard.vue";
import HorizontalProfilesCarousels from './HorizontalProfilesCarousels.vue';
export default {
    props: ["post"],
    components: {GroupCard, HorizontalProfilesCarousels},
    computed:{
        profiles(){
            if(!this.post || !this.post.content) return [];
            let profiles = []
            this.post.content.split("\n").slice(1).forEach((p)=>{
                if(p) profiles.push(p.split(':')[1]);
            })
            return profiles
        }
    }
}
</script>

<style lang="sass" scoped>
.profile
    display: table-cell
</style>